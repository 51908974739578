import { ValidationError } from "./validation-error";
import isEmail from 'validator/lib/isEmail';
import isAlpha from 'validator/lib/isAlpha';
import isAlphanumeric from 'validator/lib/isAlphanumeric';
import matches from 'validator/lib/matches';
import isPostalCode from 'validator/lib/isPostalCode';
import isMobilePhone from 'validator/lib/isMobilePhone';
//`import isISO8601 from 'validator/lib/isISO8601';

export class Validators {

  checkAndPushErrorMessage(fieldName: string, error: ValidationError, errorMessage: string) {
    if (!error) {
      error = new ValidationError();
    }
    if (!error[fieldName]) {
      error[fieldName] = [];
    }
    error[fieldName].push(errorMessage);
  }

  requiredNumber(error: ValidationError, fieldName: string, fieldValue: number, fieldDisplayName: string) {
    if (!fieldValue || ('' + fieldValue).trim() === '') {
      this.checkAndPushErrorMessage(fieldName, error, fieldDisplayName + ' is a required field, it can\'t be empty.');
    }
  }

  requiredBoolean(error: ValidationError, fieldName: string, fieldValue: boolean, fieldDisplayName: string) {
    if (!(('' + fieldValue) === 'true' || ('' + fieldValue) === 'false')) {
      this.checkAndPushErrorMessage(fieldName, error, fieldDisplayName + ' is a required field.');
    }
  }

  positiveNumber(error: ValidationError, fieldName: string, fieldValue: number, fieldDisplayName: string) {
    if (!fieldValue || ('' + fieldValue).trim() === '') {
      this.checkAndPushErrorMessage(fieldName, error, fieldDisplayName + ' should be greater than 0.');
    } else {
      if (fieldValue <= 0) {
        this.checkAndPushErrorMessage(fieldName, error, fieldDisplayName + ' should be greater than 0.');
      }
    }
  }

  zeroAndPositiveNumber(error: ValidationError, fieldName: string, fieldValue: number, fieldDisplayName: string) {
    if (!fieldValue || ('' + fieldValue).trim() === '') {
      this.checkAndPushErrorMessage(fieldName, error, fieldDisplayName + ' should be greater than or equal to 0.');
    } else {
      if (fieldValue < 0) {
        this.checkAndPushErrorMessage(fieldName, error, fieldDisplayName + ' should be greater than or equal to 0.');
      }
    }
  }

  requiredString(error: ValidationError, fieldName: string, fieldValue: string, fieldDisplayName: string) {
    if (!fieldValue || fieldValue.trim() === '') {
      this.checkAndPushErrorMessage(fieldName, error, fieldDisplayName + ' is a required field, it can\'t be empty.');
    }
  }

  requiredStringLength(error: ValidationError, fieldName: string, fieldValue: string, reqLength: number, fieldDisplayName: string) {
    if (!fieldValue || fieldValue.trim() === '' || fieldValue.trim().length < reqLength) {
      this.checkAndPushErrorMessage(fieldName, error, fieldDisplayName + ' should have at-least ' + reqLength + ' characters.');
    }
  }

  stringOnlyAlphaAndSpace(error: ValidationError, fieldName: string, fieldValue: string, fieldDisplayName: string) {
    if (!fieldValue) {
      this.checkAndPushErrorMessage(fieldName, error, fieldDisplayName + ' can contain only Alphabet and space characters.');
    } else {
      fieldValue = ('' + fieldValue).replace(' ', '');
      if (!isAlpha(fieldValue)) {
        this.checkAndPushErrorMessage(fieldName, error, fieldDisplayName + ' can contain only Alphabet and space characters.');
      }
    }

  }

  stringOnlyAlpha(error: ValidationError, fieldName: string, fieldValue: string, fieldDisplayName: string) {
    if (!isAlpha(fieldValue)) {
      this.checkAndPushErrorMessage(fieldName, error, fieldDisplayName + ' can contain only Alphabet characters.');
    }
  }


  stringOnlyAlphaNumericAndSpace(error: ValidationError, fieldName: string, fieldValue: string, fieldDisplayName: string) {
    if (!fieldValue) {
      this.checkAndPushErrorMessage(fieldName, error, fieldDisplayName + ' can contain only Alphabet, Number and space characters.');
    } else {
      fieldValue = ('' + fieldValue).replace(' ', '');
      if (!isAlphanumeric(fieldValue)) {
        this.checkAndPushErrorMessage(fieldName, error, fieldDisplayName + ' can contain only Alphabet, Number and space characters.');
      }
    }

  }

  strongPasswordValidator(error: ValidationError, fieldName: string, fieldValue: string, fieldDisplayName: string) {
    if (!fieldValue) {
      this.checkAndPushErrorMessage(fieldName, error, fieldDisplayName + ' must have at-least 8 characters length, 1 lowercase, 1 uppercase, 1 number and 1 special characters.');
    } else {
      if (!matches(fieldValue, /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/)) {
        this.checkAndPushErrorMessage(fieldName, error, fieldDisplayName + ' must have at-least 8 characters length, 1 lowercase, 1 uppercase, 1 number and 1 special characters.');
      }
    }

  }

  validateEmail(error: ValidationError, fieldName: string, fieldValue: string, fieldDisplayName: string) {
    if (!fieldValue) {
      this.checkAndPushErrorMessage(fieldName, error, fieldDisplayName + ' is not a valid EMail address.');
    } else {
      if (!isEmail(fieldValue)) {
        this.checkAndPushErrorMessage(fieldName, error, fieldDisplayName + ' is not a valid EMail address.');
      }
    }
  }

  validatePostalCode(error: ValidationError, fieldName: string, fieldValue: string, fieldDisplayName: string) {
    if (!fieldValue) {
      this.checkAndPushErrorMessage(fieldName, error, fieldDisplayName + ' is not a valid Postal Code.');
    } else {
      if (!isPostalCode(fieldValue, 'any')) {
        this.checkAndPushErrorMessage(fieldName, error, fieldDisplayName + ' is not a valid Postal Code.');
      }
    }
  }

  mobileLocale = ['ar-AE', 'ar-DZ', 'ar-EG', 'ar-IQ', 'ar-JO', 'ar-KW', 'ar-SA', 'ar-SY', 'ar-TN', 'be-BY', 'bg-BG', 'bn-BD', 'cs-CZ', 'de-DE', 'da-DK', 'el-GR', 'en-AU', 'en-CA', 'en-GB', 'en-GH', 'en-HK', 'en-IN', 'en-KE', 'en-MU', 'en-NG', 'en-NZ', 'en-RW', 'en-SG', 'en-UG', 'en-US', 'en-TZ', 'en-ZA', 'en-ZM', 'en-PK', 'es-ES', 'es-MX', 'es-UY', 'et-EE', 'fa-IR', 'fi-FI', 'fr-FR', 'he-IL', 'hu-HU', 'it-IT', 'ja-JP', 'kk-KZ', 'ko-KR', 'lt-LT', 'ms-MY', 'nb-NO', 'nn-NO', 'pl-PL', 'pt-PT', 'pt-BR', 'ro-RO', 'ru-RU', 'sl-SI', 'sk-SK', 'sr-RS', 'sv-SE', 'th-TH', 'tr-TR', 'uk-UA', 'vi-VN', 'zh-CN', 'zh-HK', 'zh-TW'];
  validateMobileNumber(error: ValidationError, fieldName: string, fieldValue: string, fieldDisplayName: string) {
    if (!fieldValue) {
      this.checkAndPushErrorMessage(fieldName, error, fieldDisplayName + ' is not a valid Mobile number.');
    } else {
      if (!isMobilePhone(fieldValue)) { //, this.mobileLocale
        this.checkAndPushErrorMessage(fieldName, error, fieldDisplayName + ' is not a valid Mobile number.');
      }
    }
  }

  validateDate(error: ValidationError, fieldName: string, fieldValue: Date, fieldDisplayName: string) {
    if (!fieldValue) {
      this.checkAndPushErrorMessage(fieldName, error, fieldDisplayName + ' is not a valid Date.');
    } else {
      if (!(fieldValue instanceof Date)) {
        this.checkAndPushErrorMessage(fieldName, error, fieldDisplayName + ' is not a valid Date.');
      }
    }
  }

  validatePasswordMatch(error: ValidationError, fieldName: string, fieldValue: string, password: string, fieldDisplayName: string) {
    if (!fieldValue || !password) {
      this.checkAndPushErrorMessage(fieldName, error, fieldDisplayName + ' is not match with password.');
    } else {
      if (fieldValue != password) {
        this.checkAndPushErrorMessage(fieldName, error, fieldDisplayName + ' is not match with password.');
      }
    }
  }

  validateName(error, fieldName, fieldValue, fieldDisplayName) {
    let nameRegex = /^[a-zA-Z '.-]{1,100}$/;
    if (!fieldValue) {
      this.checkAndPushErrorMessage(fieldName, error, fieldDisplayName + " not a valid Name, must have at-least 1 and max 100 characters. And only Alphabets, -, . and ' are allowed.");
    } else if (!nameRegex.test(fieldValue)) {
      this.checkAndPushErrorMessage(fieldName, error, fieldDisplayName + " not a valid Name, must have at-least 1 and max 100 characters. And only Alphabets, -, . and ' are allowed.");
    }
  };


  validateRosterCrewCellPhone(error, fieldName, fieldValue, fieldDisplayName) {
    if (!fieldValue) {
      this.checkAndPushErrorMessage(fieldName, error, fieldDisplayName + ' number must have 10 characters.');
    }
    else if (fieldValue.length !== 10) {
      this.checkAndPushErrorMessage(fieldName, error, fieldDisplayName + ' number must have 10 characters.');
    }
    else {
      if (!isMobilePhone(fieldValue)) { //, this.mobileLocale
        this.checkAndPushErrorMessage(fieldName, error, fieldDisplayName + ' number must have 10 characters.');
      }
    }
  };
}

